

































.admin-area {
  position: relative;
}
