



























































































































.users{
  .admin, .editor {
    @apply bg-aba-blue text-white px-sm;
    width: min-content;
    &.editor {
      @apply bg-aba-blue-semi;
    }
  }
  .visitor {
    @apply text-gray-400 italic;
  }
}
